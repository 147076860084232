import classnames from "classnames"
import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Controller, Scene } from "react-scrollmagic"
import { useWindowSize } from "react-use"

import PageHeader from "../../components/page-header/page-header"
import SEO from "../../components/seo"
import SideMenu from "../../components/side-menu/side-menu"
import Tile from "../../components/tile/tile"
import useHistoryItems from "../../hooks/useHistoryItems"
import useNav from "../../hooks/useNav"
import { removeLocaleFromUri } from "../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../i18n/translate"
import "./history.sass"

const HistoryPage = ({ location }) => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()
  const [leftOffset, setLeftOffset] = useState(0)

  const rootNav = useNav({
    path: removeLocaleFromUri(location.pathname),
    getRootLevel: true,
  })
  const parentNav = rootNav.getParentOf({
    path: removeLocaleFromUri(location.pathname),
  })

  const historyItems = useHistoryItems()
    .map((historyItem) => {
      return {
        ...historyItem,
        translatedText: t(historyItem.text.key),
        translatedTitle: t(historyItem.title.key),
      }
    })
    .sort((a, b) => a.year - b.year)

  function updateLeftOffset() {
    setLeftOffset(windowSize.width < 1400 ? 200 : 0)
  }

  useEffect(() => {
    updateLeftOffset()
    window.addEventListener("resize", updateLeftOffset)
    return () => {
      window.removeEventListener("resize", updateLeftOffset)
    }
  }, [])

  // the width of the horizontal scrolling container in px
  const duration =
    (historyItems.length - 1) * 0.5 * windowSize.width + leftOffset

  const getOpacityForIndex = (index, progress) => {
    const fraction = 1 / (historyItems.length - 1)

    const start = (index - 0.5) * fraction

    return (
      1 -
      Math.abs(1 - Math.min(Math.max((progress - start) / fraction, 0), 1) * 2)
    )
  }

  const isVertical = windowSize.width < 900 || windowSize.height < 800

  return (
    <div className={"_fp-history-container"}>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("HISTORY-META_TITLE")}
        descriptionTranslatedString={t("HISTORY-META_DESCRIPTION")}
      />

      <PageHeader
        levelThreeTitle={t("HISTORY-META_TITLE")}
        levelTwoTitle={t("HISTORY-META_TITLE")}
        levelOneTitle={parentNav ? t(parentNav.translationKey) : ""}
      />

      {!isVertical && (
        <div>
          <Controller>
            <Scene duration={duration + "px"} pin={true} triggerHook="onLeave">
              {(p) => {
                const progressOffset = leftOffset / duration
                const progress =
                  p * (duration / (duration - leftOffset)) - progressOffset
                const leftFactor = -progress * (historyItems.length - 1)

                const getLeft = (singleItemWidth) =>
                  leftFactor * singleItemWidth
                const getLeftWithoutOffset = (singleItemWidth) =>
                  -(progress + progressOffset) *
                  (historyItems.length - 1) *
                  singleItemWidth

                return (
                  <div className="_fp-history">
                    <div className="_fp-history__container">
                      <div className="_fp-global-container">
                        <motion.div
                          className="_fp-history__sidebar"
                          style={{ x: getLeftWithoutOffset(1400) }}
                        >
                          <Tile tileSize={"s"}>
                            <SideMenu
                              nav={rootNav}
                              pathName={location.pathname}
                            />
                          </Tile>
                        </motion.div>
                      </div>

                      <motion.div
                        className="_fp-history__text-layer"
                        style={{ y: "-50%", x: getLeft(1400) }}
                      >
                        {historyItems.map((event, index) => {
                          return (
                            <div
                              className="_fp-history__text"
                              key={`history-text-${index}`}
                            >
                              {event.translatedTitle && (
                                <h3
                                  className={
                                    "_fp-heading-3 _fp-history__text__title"
                                  }
                                >
                                  {event.translatedTitle}
                                </h3>
                              )}
                              <p className={"_fp-text"}>
                                {event.translatedText}
                              </p>
                            </div>
                          )
                        })}
                      </motion.div>

                      <motion.div
                        className="_fp-history__image-layer"
                        style={{ y: "-50%", x: getLeft(1000) }}
                      >
                        {historyItems.map((event, index) => {
                          return (
                            <div
                              className="_fp-history__image"
                              key={`history-image-${index}`}
                            >
                              {event.image &&
                                event.image.image &&
                                event.image.image.localFile && (
                                  <GatsbyImage
                                    image={
                                      event.image.image.localFile
                                        .childImageSharp.gatsbyImageData
                                    }
                                    alt={""}
                                  />
                                )}
                              <div
                                className="_fp-history__image__overlay"
                                style={{
                                  opacity:
                                    1 -
                                    Math.min(
                                      1,
                                      getOpacityForIndex(index, progress) *
                                        1.2 +
                                        0.3,
                                    ),
                                }}
                              />
                            </div>
                          )
                        })}
                      </motion.div>

                      <motion.div
                        className="_fp-history__year-layer"
                        style={{ y: "-50%", x: getLeft(700) }}
                      >
                        {historyItems.map((event, index) => {
                          return (
                            <div
                              className="_fp-history__year"
                              key={`history-text-${index}`}
                            >
                              <p
                                style={{
                                  opacity: Math.min(
                                    1,
                                    getOpacityForIndex(index, progress) * 1.4 +
                                      0.2,
                                  ),
                                }}
                              >
                                {t(event.year_label.key)}
                              </p>
                            </div>
                          )
                        })}
                      </motion.div>

                      <div className="_fp-history__timeline">
                        <motion.div
                          className="_fp-history__timeline__flex-container"
                          style={{ x: getLeft(300) }}
                        >
                          <div className="_fp-history__timeline__bar">
                            <motion.div
                              className="_fp-history__timeline__bar__indicator"
                              style={{ width: Math.max(0, -getLeft(300)) }}
                            />
                          </div>

                          {historyItems.map((event, index) => {
                            return (
                              <div
                                className="_fp-history__timeline__label"
                                key={`history-timeline-${index}`}
                              >
                                <div
                                  className={classnames({
                                    "_fp-history__timeline__label__dot": true,
                                    "_fp-history__timeline__label__dot--active":
                                      progress >=
                                      index / (historyItems.length - 1),
                                  })}
                                />
                                <span>{t(event.year_label.key)}</span>
                              </div>
                            )
                          })}
                        </motion.div>
                      </div>
                    </div>
                  </div>
                )
              }}
            </Scene>
          </Controller>
        </div>
      )}

      {isVertical && (
        <div className="_fp-history-mobile">
          <div className="_fp-global-container">
            {historyItems.map((event, index) => {
              return (
                <div
                  className="_fp-history-mobile__item"
                  key={`mobile-history-item-${index}`}
                >
                  <div className="_fp-history-mobile__item__year">
                    {t(event.year_label.key)}
                  </div>
                  {event.image && (
                    <div className="_fp-history-mobile__item__image">
                      <GatsbyImage
                        image={
                          event.image.image.localFile.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </div>
                  )}
                  <div className="_fp-history-mobile__item__text">
                    {event.translatedTitle && (
                      <h3
                        className={
                          "_fp-heading-3 _fp-history-mobile__item__text__title"
                        }
                      >
                        {event.translatedTitle}
                      </h3>
                    )}
                    <p className={"_fp-text"}>{event.translatedText}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default HistoryPage
